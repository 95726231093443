import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { formatDateRange } from '../../utils/helper';

const TourInformationWindow = ({ selectedTour = null, tourContractAndRouteInfo, tripSetting }) => {
    return (
        <Card className="mx-1 py-3 px-1" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
            <h4 className="mb-4 pl-2" style={{ color: `${tripSetting.textColor}` }}>
                Trip Information
            </h4>

            <Row className="w-100" style={{ margin: '4px' }}>
                <Col md={4} xs={12} className="d-flex">
                    <p className="bold" style={{ color: `${tripSetting.textColor}` }}>
                        Date(s):
                    </p>
                </Col>
                <Col md={8} xs={12}>
                    <p>{formatDateRange(selectedTour.startDate, selectedTour.endDate)}</p>
                </Col>
            </Row>

            {tourContractAndRouteInfo.importantInformation && (
                <Row className="w-100" style={{ margin: '4px' }}>
                    <Col md={4} xs={12} className="bold" style={{ color: `${tripSetting.textColor}` }}>
                        Important Information:
                    </Col>
                    <Col md={8} xs={12}>
                        <p>{tourContractAndRouteInfo.importantInformation}</p>
                    </Col>
                </Row>
            )}

            {selectedTour.tourInclusions && (
                <Row className="w-100" style={{ margin: '4px' }}>
                    <Col md={4} xs={12} className="bold" style={{ color: `${tripSetting.textColor}` }}>
                        Inclusions:
                    </Col>
                    <Col md={8} xs={12}>
                        <p>{selectedTour.tourInclusions}</p>
                    </Col>
                </Row>
            )}
            {selectedTour.tourExclusions && (
                <Row className="w-100" style={{ margin: '4px' }}>
                    <Col md={4} xs={12} className="bold" style={{ color: `${tripSetting.textColor}` }}>
                        Exclusions:
                    </Col>
                    <Col md={8} xs={12}>
                        <p>{selectedTour.tourExclusions}</p>
                    </Col>
                </Row>
            )}
            {selectedTour.tourWhatToBring && (
                <Row className="w-100" style={{ margin: '4px' }}>
                    <Col md={4} xs={12} className="bold" style={{ color: `${tripSetting.textColor}` }}>
                        What to Bring:
                    </Col>
                    <Col md={8} xs={12}>
                        <p>{selectedTour.tourWhatToBring}</p>
                    </Col>
                </Row>
            )}
            {selectedTour.tourAdditionalInformation && (
                <Row className="w-100" style={{ margin: '4px' }}>
                    <Col md={4} xs={12} className="bold" style={{ color: `${tripSetting.textColor}` }}>
                        Additional Information:
                    </Col>
                    <Col md={8} xs={12}>
                        <p>{selectedTour.tourAdditionalInformation}</p>
                    </Col>
                </Row>
            )}
        </Card>
    );
};

export default TourInformationWindow;
