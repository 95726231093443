import React, { useEffect, useState } from 'react';
import MockupLayout from '../../../../Components/MockupLayout';
import CustomAccordion from '../../../../Components/CustomAccordion';
import SimpleTable from '../../../../Components/SimpleTable';
import Loading from '../../../../Components/Loading';
import { totalAmountValues, formatCurrency } from '../../../../utils/helper';
import {
    useGeneralLedgerCodeState,
    useBookingState,
    getGeneralLedgerCodes,
    getTripSettings,
    useAccountState,
    getUpcomingBookings,
    getPastBookings,
} from '../../../../Context';
import './index.scss';
import { getCustomerDetails } from '../../../../config';
import { Button, Form, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import moment from 'moment';
import HelperFunction from '../../../../Components/HelperFunction';
import { printSignedBookingService } from '../../../../services/bookingsService';

const currencyRenderer = (item, key) => {
    return item[key] ? `$${formatCurrency(item[key])}` : '$0.00';
};

const MockupBookings = ({ history }) => {
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        bookingsDispatch,
        bookings: { pastBookings, upcomingBookings, upcomingBookingsCopy },
    } = useBookingState();
    const {
        generalLedgerCodeDispatch,
        generalLedgerCode: { generalLedgerCodes },
    } = useGeneralLedgerCodeState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [upcomingFilter, setUpcomingFilter] = useState('30');
    const [pastFilter, setPastFilter] = useState('30');
    const [bookingsLoading, setBookingsLoading] = useState({ past: false, upcoming: false });
    const [loading, setLoading] = useState(false);
    const [upcomingSearchFilter, setUpcomingSearchFilter] = useState('');
    const [pastSearchFilter, setPastSearchFilter] = useState('');
    const [contractDownloadLoading, setContractDownloadLoading] = useState(false);
    const styles = { cancelled: { backgroundColor: 'rgb(240, 128, 128)' } };
    const user = getCustomerDetails();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getUpcomingBookings(bookingsDispatch, {
                startDate: getStartDate('30', 'upcoming'),
                endDate: getEndDate('30', 'upcoming'),
            });
            await getPastBookings(bookingsDispatch, {
                startDate: getStartDate('30', 'past'),
                endDate: getEndDate('30', 'past'),
            });
            await getGeneralLedgerCodes(generalLedgerCodeDispatch);
            setLoading(false);
        })();
    }, [bookingsDispatch, generalLedgerCodeDispatch, AccountsDispatch]);

    const rowStyle = (item) => {
        return styles[item.cancelledAt ? 'cancelled' : ''];
    };
    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    const onPaymentClick = (e, item) => {
        e.stopPropagation();
        window.open(
            `${window.location.origin}/p/booking-payment?bid=${item.id}&ts=${new Date(item.createdAt).valueOf()}`,
            '_blank'
        );
    };

    const handleSignAndAcceptClick = async (event, item) => {
        event.stopPropagation();
        window.open(
            `${window.location.origin}/p/booking-desclaimer?bid=${item.id}&ts=${new Date(item.createdAt).valueOf()}`,
            '_blank'
        );
    };

    const refreshBookings = async () => {
        await getUpcomingBookings(bookingsDispatch, {
            startDate: getStartDate(upcomingFilter, 'upcoming'),
            endDate: getEndDate(upcomingFilter, 'upcoming'),
        });
        await getPastBookings(bookingsDispatch, {
            startDate: getStartDate(pastFilter, 'past'),
            endDate: getEndDate(pastFilter, 'past'),
        });
    };

    const renderTotal = (item, key) => {
        if (item.invoiceID) {
            return 'INVOICED';
        }
        return item[key] ? `$${formatCurrency(Number(item[key]))}` : '$0.00';
    };

    const renderHoverModal = (item) => {
        return (
            <OverlayTrigger
                style={{ 'max-width': '100%' }}
                overlay={
                    <Popover style={{ 'max-width': '100%' }}>
                        <div style={{ padding: '0.4rem', background: 'white' }}>
                            <SimpleTable
                                loading={loading}
                                onClick={() => {}}
                                data={item.vehicleData}
                                bordered={false}
                                wrap={true}
                                showFooter={false}
                                mainColor={tripSettings?.mainColor}
                                fieldNames={[
                                    ['routeDescription', 'Route Description'],
                                    ['vehicleDescription', 'Vehicle Description'],
                                    ['driverName', 'Driver'],
                                    ['vehicleID', 'Vehicle ID'],
                                    ['price', 'Price', (item) => currencyRenderer(item, 'price')],
                                ]}
                            />
                        </div>
                    </Popover>
                }
            >
                <div>{item.numVehicles}</div>
            </OverlayTrigger>
        );
    };

    const renderMakePayment = (item) => {
        return item.paymentTermID && Number(item.paymentTermID) === 1 ? (
            item.amountOutstanding > 0 ? (
                <i className="fa fa-credit-card" onClick={(e) => onPaymentClick(e, item)} />
            ) : (
                ''
            )
        ) : (
            <i>Invoice Pending</i>
        );
    };

    const handleContractDownload = async (event, item) => {
        try {
            event.stopPropagation();
            setContractDownloadLoading(true);
            const pdfData = await printSignedBookingService(item.id, '', '', '', 1);
            setContractDownloadLoading(false);
            let blob = new Blob([pdfData.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            link.download = 'Booking (' + item.bookingFormattedID + ')';
            link.click();
        } catch (e) {
            setContractDownloadLoading(false);
            window.swal('Not Found', 'No Contract Found!', 'error');
        }
    };

    const renderSignAndAccept = (item) => {
        return !item.bookingConfirmationSignedAt ? (
            <Button onClick={(e) => handleSignAndAcceptClick(e, item)} variant="success">
                Sign & Accept
            </Button>
        ) : contractDownloadLoading ? (
            <Spinner animation="border" size="sm" />
        ) : (
            <Button onClick={(e) => handleContractDownload(e, item)} variant="success" disabled={loading}>
                Download
            </Button>
        );
    };

    const onRowClick = (item) => {
        history.push(`/bookings/${item.id}`);
    };
    const fieldNamesUpcoming = [
        ['bookingFormattedID', 'Booking ID', '', 'TOT', true],
        ['tripReference', 'Trip Reference', '', '', true],
        ['groupName', 'Group Name', '', '', true],
        ['numVehicles', 'Number Of Vehicles', renderHoverModal, '', true],
        ['departureTime', 'Earliest Departure Date', '', '', true],
        [
            'bookingTotal',
            'Booking Total',
            (item) => renderTotal(item, 'bookingTotal'),
            totalAmountValues(upcomingBookings, 'bookingTotal'),
            true,
        ],
        [
            'amountOutstanding',
            'Amount Outstanding',
            (item) => renderTotal(item, 'amountOutstanding'),
            totalAmountValues(upcomingBookings, 'amountOutstanding'),
            true,
        ],
    ];
    const fieldNamesPrior = [
        ['bookingId', 'Booking ID', '', 'TOT', true],
        ['tripReference', 'Trip Reference', '', '', true],
        ['groupName', 'Group Name', '', '', true],
        ['numVehicles', 'Number Of Vehicles', renderHoverModal, '', true],
        ['departureTime', 'Earliest Departure Date', '', '', true],
        [
            'bookingTotal',
            'Booking Total',
            (item) => renderTotal(item, 'bookingTotal'),
            totalAmountValues(pastBookings, 'bookingTotal'),
            true,
        ],
        [
            'amountOutstanding',
            'Amount Outstanding',
            (item) => renderTotal(item, 'amountOutstanding'),
            totalAmountValues(pastBookings, 'amountOutstanding'),
            true,
        ],
    ];
    if (user.showParentCompany) {
        fieldNamesPrior.unshift(['parentCompanyName', 'Parent Company', '', '', false]);
        fieldNamesPrior.unshift(['companyName', 'Company', '', '', false]);
        fieldNamesUpcoming.unshift(['parentCompanyName', 'Parent Company', '', '', false]);
        fieldNamesUpcoming.unshift(['companyName', 'Company', '', '', false]);
    } else if (user.showCompany) {
        fieldNamesPrior.unshift(['companyName', 'Company', '', '', false]);
        fieldNamesUpcoming.unshift(['companyName', 'Company', '', '', false]);
    }

    fieldNamesPrior.push(['bookingConfirmationSignedAt', 'Sign & Accept', renderSignAndAccept, '', false]);
    fieldNamesUpcoming.push(['bookingConfirmationSignedAt', 'Sign & Accept', renderSignAndAccept, '', false]);

    if (tripSettings && !tripSettings.disablePayment) {
        fieldNamesPrior.push(['makePayment', 'Make Payment', renderMakePayment, '', false]);
        fieldNamesUpcoming.push(['makePayment', 'Make Payment', renderMakePayment, '', false]);
    }

    const getStartDate = (dayValue = '30', type = 'past') => {
        if (type === 'past') {
            return dayValue === '-1'
                ? moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().startOf('day').subtract(Number(dayValue), 'days').format('YYYY-MM-DD HH:mm:ss');
        } else {
            return dayValue === '1'
                ? moment().startOf('day').add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
    };

    const getEndDate = (dayValue = '30', type = 'past') => {
        if (type === 'past') {
            return dayValue === '-1'
                ? moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        } else {
            return dayValue === '1'
                ? moment().endOf('day').add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
                : moment().endOf('day').add(Number(dayValue), 'days').format('YYYY-MM-DD HH:mm:ss');
        }
    };

    const handleBookingDropdownChange = async (type = 'past', days) => {
        if (type === 'past') {
            setBookingsLoading({ ...bookingsLoading, past: true });
            await getPastBookings(bookingsDispatch, {
                startDate: getStartDate(days, 'past'),
                endDate: getEndDate(days, 'past'),
            });
            setPastFilter(days);
            setBookingsLoading({ ...bookingsLoading, past: false });
        } else {
            setBookingsLoading({ ...bookingsLoading, upcoming: true });
            await getUpcomingBookings(bookingsDispatch, {
                startDate: getStartDate(days, 'upcoming'),
                endDate: getEndDate(days, 'upcoming'),
            });
            setLoading(false);
            setUpcomingFilter(days);
            setBookingsLoading({ ...bookingsLoading, upcoming: false });
        }
    };
    const handleUpcomingSearch = async (e) => {
        setUpcomingSearchFilter(e.target.value);
        if (e.target.value !== '' || typeof e.target.value !== 'undefined') {
            setLoading(true);
            bookingsDispatch({ type: 'FILTER_UPCOMING_BY_SEARCH_TEXT', payload: { searchText: e.target.value } });
            setLoading(false);
        }
    };
    const handlePastSearch = async (e) => {
        setPastSearchFilter(e.target.value);
        if (e.target.value !== '' || typeof e.target.value !== 'undefined') {
            setLoading(true);
            bookingsDispatch({ type: 'FILTER_PAST_BY_SEARCH_TEXT', payload: { searchText: e.target.value } });
            setLoading(false);
        }
    };

    return !loading && tripSettings ? (
        <MockupLayout title={'Bookings'} titleIconClass={'fas fa-calendar-check'} textColor={tripSettings?.textColor}>
            <CustomAccordion
                id={1}
                title={'Upcoming Bookings'}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <div className="d-flex justify-content-between mb-2">
                    <Form.Control
                        as="select"
                        value={upcomingFilter}
                        className="w-25"
                        onChange={(e) => handleBookingDropdownChange('upcoming', e.target.value)}
                    >
                        <option value="0">Today</option>
                        <option value="1">Tommorow</option>
                        <option value="7">Next 7 Days</option>
                        <option value="30">Next 30 Days</option>
                        <option value="60">Next 60 Days</option>
                        <option value="180">Next 180 Days</option>
                        <option value="36500">All Time</option>
                    </Form.Control>
                    <div className="d-flex mr-5">
                        <HelperFunction message="This field will only search records the correspond to the date filter currently selected." />

                        <input
                            className="form-control"
                            value={upcomingSearchFilter}
                            id="upcoming_bookings_search"
                            onChange={handleUpcomingSearch}
                            type="search"
                            placeholder="Search Bookings..."
                        />
                    </div>
                </div>
                <SimpleTable
                    fieldNames={fieldNamesUpcoming}
                    rowStyle={rowStyle}
                    data={upcomingBookings}
                    showFooter={true}
                    mainColor={tripSettings?.mainColor}
                    bordered={true}
                    loading={bookingsLoading?.upcoming}
                    onClick={onRowClick}
                    simpleSort
                    sortCol={true}
                />
            </CustomAccordion>

            <CustomAccordion
                id={2}
                title={'Past Bookings'}
                collapseItem={collapseItem}
                handleItemCollapse={handleItemCollapse}
                sectionColor={tripSettings?.sectionColor}
            >
                <div className="d-flex justify-content-between mb-2">
                    <Form.Control
                        as="select"
                        value={pastFilter}
                        className="w-25"
                        onChange={(e) => handleBookingDropdownChange('past', e.target.value)}
                    >
                        <option value="0">Today</option>
                        <option value="-1">Yesterday</option>
                        <option value="7">Last 7 Days</option>
                        <option value="30">Last 30 Days</option>
                        <option value="60">Last 60 Days</option>
                        <option value="180">Last 180 Days</option>
                        <option value="36500">All Time</option>
                    </Form.Control>
                    <div className="d-flex mr-5">
                        <HelperFunction message="This field will only search records the correspond to the date filter currently selected." />
                        <input
                            className="form-control"
                            value={pastSearchFilter}
                            id="past_bookings_search"
                            onChange={handlePastSearch}
                            type="search"
                            placeholder="Search Bookings..."
                        />
                    </div>
                </div>
                <SimpleTable
                    fieldNames={fieldNamesPrior}
                    rowStyle={rowStyle}
                    data={pastBookings}
                    bordered={true}
                    mainColor={tripSettings?.mainColor}
                    showFooter={true}
                    loading={bookingsLoading?.past}
                    onClick={onRowClick}
                    simpleSort
                    sortCol={true}
                />
            </CustomAccordion>
        </MockupLayout>
    ) : (
        <Loading loading={loading} />
    );
};

export default MockupBookings;
