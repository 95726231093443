import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import './style.css';

// Selected Date in YYYY-MM-DD
const TourCalendarSelection = ({ data, tripSetting, setSelectedTour, selectedEvent, setSelectedEvent }) => {
    const [calendarDates, setCalendarDates] = useState({
        available: [],
        selectedDate: [],
    });
    const [eventData, setEventData] = useState([]);
    const calendarRef = useRef();

    useEffect(() => {
        if (data && Array.isArray(data) && data.length > 0) {
            const dates = new Set(data.map((d) => d.startDate));
            setCalendarDates({
                available: Array.from(dates),
                selectedDate: [],
            });

            const eventDataToSet = data.map((d) => {
                const startDate = moment(d.startDate?.replaceAll('-', '/'));
                const endDate = moment(d.endDate?.replaceAll('-', '/'));

                const isSameDay = startDate.isSame(endDate, 'day');

                return {
                    ...d,
                    start: startDate.format(isSameDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDT00:00:00'),
                    end: endDate.format(isSameDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDT23:59:59'),
                    color: '#17a2b8',
                    borderColor: '#383838',
                };
            });

            setEventData(eventDataToSet);
            let calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(eventDataToSet[0].start);
        }
    }, [data]);

    const onDatePicked = async (e, isNumberClick = false) => {
        if (e.event === undefined) {
            setSelectedTour(null);
            setSelectedEvent(null);
            setEventData(eventData.map((ed) => ({ ...ed, color: '#17a2b8' })));
            return;
        }

        let selectedEventObj = {
            id: e.event?._def?.publicId,
            startDate: moment(e.event?.start).format('MM-DD-YYYY'),
            endDate: e.event?.end
                ? moment(e.event?.end).format('MM-DD-YYYY')
                : moment(e.event?.start).format('MM-DD-YYYY'),
        };

        const date = isNumberClick ? moment(e.date) : moment(e.event?.start);
        let newCalendarDates = {
            ...calendarDates,
        };

        setSelectedTour({});

        let currentSelectedDate = date.format('MM-DD-YYYY');
        if (selectedEvent?.startDate === currentSelectedDate) {
            newCalendarDates.selectedDate = [];
            setSelectedEvent(null);
            setEventData(
                eventData.map((ed) => ({
                    ...ed,
                    color: '#17a2b8',
                }))
            );
            setSelectedTour(null);
        } else if (calendarDates.available.includes(currentSelectedDate)) {
            newCalendarDates.selectedDate = [currentSelectedDate];
            setSelectedEvent(selectedEventObj);

            const eventDataToSet = eventData.map((ed) => {
                const endDate = moment(ed.start).format('YYYY-MM-DD');
                const comparisonDate = date.format('YYYY-MM-DD');
                return {
                    ...ed,
                    color: endDate === comparisonDate ? '#78b343' : '#17a2b8',
                };
            });

            const selectedTour = data.find((tour) => tour.startDate === currentSelectedDate);
            setSelectedTour(selectedTour);
            setEventData(eventDataToSet);
        }

        setCalendarDates(newCalendarDates);
    };

    return (
        <Card className="card-wrapper p-1" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
            <Card.Title>
                <h3 className="text-center" style={{ color: `${tripSetting.textColor}` }}>
                    Select Date
                </h3>
            </Card.Title>
            <Card.Body style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                <div className="calendar-wrapper-2">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        selectable={true}
                        headerToolbar={{
                            left: 'prev',
                            center: 'title',
                            right: 'next',
                        }}
                        events={eventData}
                        displayEventTime={false}
                        eventClick={(e) => onDatePicked(e)}
                        dayHeaderFormat={{ weekday: 'short' }}
                        height="650px"
                        dateClick={(e) => {
                            onDatePicked(e, true);
                        }}
                    />
                    <Fragment>
                        <div className="d-flex">
                            <div className="dispatch-available" style={{ width: '20px' }} />
                            <div>Date Available</div>
                        </div>
                        <div className="d-flex">
                            <div className="dispatch-chosen" style={{ width: '20px' }} />
                            <div>Date Selected</div>
                        </div>
                    </Fragment>
                </div>
            </Card.Body>
        </Card>
    );
};

export default TourCalendarSelection;
